import { environmentNames } from "./static/environmentNames";
import { environmentsMap } from "./static/environmentsMap";

export default class Environments {
  constructor() {
    const env = environmentsMap[window.location.host];

    const DEFAULT_VALUES = {
      AUTHORITY: "https://accounts-cdt.maersk.com/thirdparty-cdt",
      CLIENT_ID: "thirdparty",
      LOGOUT_URI: "https://accounts-cdt.maersk.com/thirdparty-cdt/auth/logout",
      REDIRECT_PATH: "login-callback",
      BASE_URI: window.location.origin,
      ORIGINAL_URL_PARAM_NAME: "mytruckOriginalURI",
    };

    let OVERRIDES = {};
    switch (env) {
      case environmentNames.DEV:
        OVERRIDES = {
          COOKIE_ID_TOKEN_STORE_NAME: "iam.thirdparty-cdt.mytruckadv-auth",
          COOKIE_ACCESS_TOKEN_STORE_NAME: "iam.thirdparty-cdt.mytruckadv-access-token",
          COOKIE_REFRESH_TOKEN_STORE_NAME: "iam.thirdparty-cdt.mytruckadv-refresh-token",
          COOKIE_EXPIRY_DATE_STORE_NAME: "iam.thirdparty-cdt.mytruckadv-expiry-date",
        };
        break;
      case environmentNames.TEST:
        OVERRIDES = {
          COOKIE_ID_TOKEN_STORE_NAME: "iam.thirdparty-cdt.mytruckcdt-auth",
          COOKIE_ACCESS_TOKEN_STORE_NAME: "iam.thirdparty-cdt.mytruckcdt-access-token",
          COOKIE_REFRESH_TOKEN_STORE_NAME: "iam.thirdparty-cdt.mytruckcdt-refresh-token",
          COOKIE_EXPIRY_DATE_STORE_NAME: "iam.thirdparty-cdt.mytruckcdt-expiry-date",
        };
        break;
      case environmentNames.EDU:
        OVERRIDES = {
          COOKIE_ID_TOKEN_STORE_NAME: "iam.thirdparty-cdt.mytruckedu-auth",
          COOKIE_ACCESS_TOKEN_STORE_NAME: "iam.thirdparty-cdt.mytruckedu-access-token",
          COOKIE_REFRESH_TOKEN_STORE_NAME: "iam.thirdparty-cdt.mytruckedu-refresh-token",
          COOKIE_EXPIRY_DATE_STORE_NAME: "iam.thirdparty-cdt.mytruckedu-expiry-date",
        };
        break;
      case environmentNames.PREPROD:
        OVERRIDES = {
          AUTHORITY: "https://accounts-stage.maersk.com/thirdparty-stage",
          LOGOUT_URI: "https://accounts-stage.maersk.com/thirdparty-stage/auth/logout",
          COOKIE_ID_TOKEN_STORE_NAME: "iam.thirdparty-stage.mytruckstage-auth",
          COOKIE_ACCESS_TOKEN_STORE_NAME: "iam.thirdparty-stage.mytruckstage-access-token",
          COOKIE_REFRESH_TOKEN_STORE_NAME: "iam.thirdparty-stage.mytruckstage-refresh-token",
          COOKIE_EXPIRY_DATE_STORE_NAME: "iam.thirdparty-stage.mytruckstage-expiry-date",
        };
        break;
      case environmentNames.PROD:
        OVERRIDES = {
          AUTHORITY: "https://accounts.maersk.com/thirdparty",
          LOGOUT_URI: "https://accounts.maersk.com/thirdparty/auth/logout",
          COOKIE_ID_TOKEN_STORE_NAME: "iam.thirdparty.mytruck-auth",
          COOKIE_ACCESS_TOKEN_STORE_NAME: "iam.thirdparty.mytruck-access-token",
          COOKIE_REFRESH_TOKEN_STORE_NAME: "iam.thirdparty.mytruck-refresh-token",
          COOKIE_EXPIRY_DATE_STORE_NAME: "iam.thirdparty.mytruck-expiry-date",
        };
        break;
      default:
        throw new Error(`Unknown Environment ${env}`);
    }
    this.configs = Object.assign(DEFAULT_VALUES, OVERRIDES);
  }
  config(config_name) {
    return this.configs[config_name];
  }
}
